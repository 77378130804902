
import BaseIcon from '@/shared/components/base-icon/BaseIcon.vue'
import { defineAsyncComponent, defineComponent } from 'vue'
export default defineComponent({
  components: {
    BaseIcon,
    Popper: defineAsyncComponent(() => import('vue3-popper'))
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  }
})
